
import { defineComponent } from "vue";

import { IonPage, IonContent, IonRow } from "@ionic/vue";

import MainHeader from "../main/MainHeader.vue";
import LanguageService, { Language } from "../../lib/service/LanguageService";
import DateModule from "../modules/date/DateModule.vue";
import VoteModule from "../modules/vote/VoteModule.vue";
import ChecklistModule from "../modules/checklist/ChecklistModule.vue";
import MapModule from "../modules/map/MapModule.vue";
import DiscussModule from "../modules/discuss/DiscussModule.vue";

export default defineComponent({
  name: "ScreenshotExample",
  components: {
    IonPage,
    IonRow,
    DateModule,
    VoteModule,
    ChecklistModule,
    IonContent,
    MapModule,
    DiscussModule,
    MainHeader,
  },
  setup() {
    if(location.hash.length > 0) {
      LanguageService.setLanguage(window.location.hash as Language);
      window.location.hash = '';
      location.reload();
    }
    return {
      lang: LanguageService.getTranslator("main/screenshot"),
    };
  },
});
