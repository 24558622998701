import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_date_module = _resolveComponent("date-module")!
  const _component_vote_module = _resolveComponent("vote-module")!
  const _component_checklist_module = _resolveComponent("checklist-module")!
  const _component_map_module = _resolveComponent("map-module")!
  const _component_discuss_module = _resolveComponent("discuss-module")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_main_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_date_module, { example: "" }),
              _createVNode(_component_vote_module, { example: "" }),
              _createVNode(_component_checklist_module, { example: "" }),
              _createVNode(_component_map_module, { example: "" }),
              _createVNode(_component_discuss_module, { example: "" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}